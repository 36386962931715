<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update'
          ? $lang.skills.crud.edit
          : $lang.skills.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>

          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label"
            >{{ $lang.industry.form.industry }}
              <required_span/>
            </label>
            <v-select :options="industryLists" v-model="skills.industryId">
            </v-select>
            <small
                class="error"
                v-if="$v.skills.industryId.$error && !$v.skills.industryId.required"
            >{{ $lang.skills.validation.required.industryId }}</small
            >
          </div>


          <div
              class="form-group"
              :class="{ 'form-group--error': $v.skills.title.$error }"
          >
            <label class="form__label"
            >{{ $lang.skills.form.title }}
              <required_span/>
            </label>
            <input
                :maxlength="maxlength.title"
                :placeholder="$lang.skills.form.title"
                class="form-control"
                v-model="skills.title"
            />
            <small
                class="error"
                v-if="$v.skills.title.$error && !$v.skills.title.required"
            >{{ $lang.skills.validation.required.title }}</small
            >
            <small
                class="error"
                v-if="$v.skills.title.$error && !$v.skills.title.minLength"
            >{{ $lang.skills.validation.minLength.title }}
              {{ $v.skills.title.$params.minLength.min }}
              {{ $lang.skills.validation.common.letter }}</small
            >
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label">{{ $lang.common.form.isActive }} </label> &nbsp;
            <input
                type="checkbox"
                :placeholder="$lang.common.form.isActive"
                :value="1"
                @change="changedIsActive"
                v-model="skills.isActive"
            />
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label">{{ $lang.common.form.isDefault }} </label> &nbsp;
            <input
                type="checkbox"
                :placeholder="$lang.common.form.isDefault"
                :value="1"
                @change="changedIsDefault"
                v-model="skills.isDefault"
            />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted"
          >
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.skills.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-skills')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Skills')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                  <CButton color="primary"
                           v-c-tooltip="$lang.buttons.general.crud.view"
                           v-on:click="NewSkillList()">
                    New Skill
                  </CButton>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="skills/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Skills')"
          >
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #title="data">
              <span :title="data.row.title">{{
                  trimfunction(data.row.title, 50)
                }}</span>
            </template>
            <template #industry="data">
              <span :title="data.row.industry.title">{{
                  trimfunction(data.row.industry.title, 50)
                }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-skills')"
              >
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-skills')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
              <!--              <CButton color="warning" v-c-tooltip="$lang.buttons.general.crud.delete" v-on:click="mergePopup(data.row.id)">-->
              <!--                <i class="fas fa-pencil-alt"></i>-->
              <!--              </CButton>-->
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {industry, skills} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {minLength, required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "SkillsItem",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      json_data: [],
      industryLists: [],
      json_label: {},
      json_title: "",
      messageColor: "success",
      submitted: false,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: skills,
      moduleIndustry: industry,
      skills: {
        title: "",
        industryId: "",
        isActive: 1,
        isDefault: 0,
      },
      maxlength: {
        title: Maxlength.skills.title,
      },
      columns: ["industry", "title", "isDefault", "status", "actions"],
      data: [],
      options: {
        headings: {
          industry: this.$lang.skills.table.industry,
          title: this.$lang.skills.table.title,
          isDefault: this.$lang.skills.table.isDefault,
          status: this.$lang.common.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["title"],
        sortable: ["title"],
        filterable: ["title"],
      },
    };
  },
  validations: {
    skills: {
      title: {
        required,
        minLength: minLength(1),
      },
      industryId: {
        required,
      }
    },
  },
  mounted() {
    let self = this;
    this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.skills.isActive = 1;
      } else {
        self.skills.isActive = 0;
      }
    },
    changedIsDefault(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.skills.isDefault = 1;
      } else {
        self.skills.isDefault = 0;
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.data.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.messageColor = "success";
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.skills.title = "";
      self.skills.industryId = {};
      self.skills.isActive = 1;
      self.skills.isDefault = 0;
      self.largeModal = true;
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data.data.details;
              self.skills.title = responseData.title;
              self.skills.isActive = responseData.isActive;
              self.skills.isDefault = responseData.isDefault;
              self.skills.industryId = {value: responseData.industry.id, label: responseData.industry.title};
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    mergePopup(item, index) {
      this.$router.push({path: `skills/merge/${item}`});
    },
    NewSkillList(item, index) {
      this.$router.push({path: `skills/other`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      // axios
      //   .get(this.listUrlApi(this.module), self.model)
      //   .then(function (response) {
      //     if (response) {
      //       self.data = response.data;
      //     } else {
      //       self.data = [];
      //     }
      //   });

      axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
        response.data.data.map(function (value, key) {
          if (value.industryId <= 0) {
            self.industryLists.push({value: value.id, label: value.industry});
          }
        });
      });
    },
    onSubmit() {
      let self = this;
      this.$v.skills.$touch();
      if (this.$v.skills.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.skills.title,
              industryId: self.skills.industryId.value,
              isActive: self.skills.isActive,
              isDefault: self.skills.isDefault,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.message;
                    self.messageColor = "success";
                    self.dismissCountDownS = 10;
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.skills.title,
              industryId: self.skills.industryId.value,
              isActive: self.skills.isActive,
              isDefault: self.skills.isDefault,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.messageColor = "success";
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
